@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-secondary text-[22px] text-secondary;
}

a {
  @apply text-[18px] hover:text-accent transition-all duration-300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-primary text-primary;
}

.h1 {
  @apply text-[64px] lg:text-[84px] leading-none;
}

.h2 {
  @apply text-[50px] leading-tight;
}

.h3 {
  @apply text-[25px];
}

.btn {
  @apply h-[75px] px-[30px] transition-all duration-300 text-white rounded-[18px] flex items-center gap-2;
}

.btn-primary {
  @apply bg-primary hover:bg-primary-hover;
}

.btn-accent {
  @apply bg-accent hover:bg-accent-hover;
}

html {
  scroll-behavior: smooth;
}

.nav-is-open {
  @apply h-[350px] p-6;
}

/* swiper pagination */
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  @apply bg-primary;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}

.overflow-hidden {
  max-width: 100%;
  overflow-x: hidden;
}
